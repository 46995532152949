:root {
  /* spacing */
  --gap-sm: 0.5rem;
  --gap: 1rem;
  --gap-md: 2rem;
  --gap-lg: 3rem;
  --gap-xl: 4rem;
  --gap-xxl: 8rem;

  /* colors */
  --color-primary: #013d8c;
  --color-secondary: #707070;
  --color-text-primary: #043b87;
  --color-border: #e1e1e1;
  --box-shadow: 0 3px 6px rgba(100, 100, 100, 0.2);

  /* sizing */
  --font-xxsm: 0.625rem;
  --font-xsm: 0.875rem;
  --font-sm: 1rem;
  --font: 1.125rem;
  --font-md: 1.25rem;
  --font-lg: 1.5rem;
  --font-xlg: 1.875rem;
  --font-xxlg: 2.25rem;
  --font-xxxlg: 3rem;

  --navbar-height: 110px;

  --radius-input: 5px;

  /* authorities sizing */
  --categories-width: 300px;
  --authorities-width: 340px;

  /* transitions */
  --transition: all 0.2s linear;
  --transition-slow: all 0.3s linear;

  /* breakpoints */
  /* 4xl: 1600px */
  /* 3xl: 1400px */
  /* 2xl: 1200px */
  /* xl: 992px */
  /* lg: 768px */
  /* md: 600px */
  /* sm: 520px */
  /* xsm: 420px */
}

* {
  box-sizing: border-box;
}

html[data-theme='default'] {
  filter: none;
}

html[data-theme='contrast'] {
  filter: grayscale(100%) invert(100%);
}

html[data-theme='blackwhite'] {
  filter: grayscale(100%);
}

html.noImage img {
  display: none !important;
}

html.noImage main svg {
  display: none !important;
}

html.noImage img.captcha {
  display: inline-block !important;
}

/* word height */

html[data-word-height='big'] main h1,
html[data-word-height='big'] main h2,
html[data-word-height='big'] main h3,
html[data-word-height='big'] main h4,
html[data-word-height='big'] main h5,
html[data-word-height='big'] main h6,
html[data-word-height='big'] main p,
html[data-word-height='big'] main span,
html[data-word-height='big'] main a,
html[data-word-height='big'] main div {
  line-height: 1.8;
}

html[data-word-height='bigger'] main h1,
html[data-word-height='bigger'] main h2,
html[data-word-height='bigger'] main h3,
html[data-word-height='bigger'] main h4,
html[data-word-height='bigger'] main h5,
html[data-word-height='bigger'] main h6,
html[data-word-height='bigger'] main p,
html[data-word-height='bigger'] main span,
html[data-word-height='bigger'] main a,
html[data-word-height='bigger'] main div {
  line-height: 2.4;
}

/* word spacing */

html[data-word-spacing='big'] main h1,
html[data-word-spacing='big'] main h2,
html[data-word-spacing='big'] main h3,
html[data-word-spacing='big'] main h4,
html[data-word-spacing='big'] main h5,
html[data-word-spacing='big'] main h6,
html[data-word-spacing='big'] main p,
html[data-word-spacing='big'] main span,
html[data-word-spacing='big'] main a,
html[data-word-spacing='big'] main div {
  word-spacing: 8px;
}

html[data-word-spacing='bigger'] main h1,
html[data-word-spacing='bigger'] main h2,
html[data-word-spacing='bigger'] main h3,
html[data-word-spacing='bigger'] main h4,
html[data-word-spacing='bigger'] main h5,
html[data-word-spacing='bigger'] main h6,
html[data-word-spacing='bigger'] main p,
html[data-word-spacing='bigger'] main span,
html[data-word-spacing='bigger'] main a,
html[data-word-spacing='bigger'] main div {
  word-spacing: 16px;
}

/* letter spacing */

html[data-letter-spacing='big'] main h1,
html[data-letter-spacing='big'] main h2,
html[data-letter-spacing='big'] main h3,
html[data-letter-spacing='big'] main h4,
html[data-letter-spacing='big'] main h5,
html[data-letter-spacing='big'] main h6,
html[data-letter-spacing='big'] main p,
html[data-letter-spacing='big'] main span,
html[data-letter-spacing='big'] main a,
html[data-letter-spacing='big'] main div {
  letter-spacing: 2px;
}

html[data-letter-spacing='bigger'] main h1,
html[data-letter-spacing='bigger'] main h2,
html[data-letter-spacing='bigger'] main h3,
html[data-letter-spacing='bigger'] main h4,
html[data-letter-spacing='bigger'] main h5,
html[data-letter-spacing='bigger'] main h6,
html[data-letter-spacing='bigger'] main p,
html[data-letter-spacing='bigger'] main span,
html[data-letter-spacing='bigger'] main a,
html[data-letter-spacing='bigger'] main div {
  letter-spacing: 4px;
}

body {
  position: relative;
  min-height: 50vh;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #f0f0f0;
}

body::-webkit-scrollbar-thumb {
  background: #013d8c;
  border-radius: 1px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #0047a5;
}

main {
  width: 100%;
  min-height: 40rem;
}

footer {
  background-color: #143797;
  color: #fff;
}

a:focus-visible {
  outline: 3px solid #2f8ee2;
  outline-offset: 3px;
}

.only-1-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.only-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.only-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.font--xxsm {
  font-size: var(--font-xxsm);
}

.font--xsm {
  font-size: var(--font-xsm);
}

.font--sm {
  font-size: var(--font-sm);
}

.font {
  font-size: var(--font);
}

.font--md {
  font-size: var(--font-md);
}

.font--lg {
  font-size: var(--font-lg);
}

.font--xlg {
  font-size: var(--font-xlg);
}

.font--xxlg {
  font-size: var(--font-xxlg);
}

.font--xxxlg {
  font-size: var(--font-xxxlg);
}

.medium-zoom-overlay,
.medium-zoom-image--opened {
  object-fit: cover;
  z-index: 9999;
}

/* server data classes */

.title_bg_grey {
  overflow-x: hidden;
  display: grid;
  place-items: center;
  padding-top: var(--gap-lg);
  padding-bottom: var(--gap-lg);
}

.title_bg_grey i {
  position: relative;
  display: inline-block;
  background-color: #e2e2e2;
  padding: 10px var(--gap-lg);
  font-size: var(--font);
  font-weight: 600;
  font-style: normal;
  line-height: 1.2;
  text-align: center;
}

.title_bg_grey i:before,
.title_bg_grey i:after {
  content: '';
  background-color: #aeaeae;
  width: 1000px;
  height: 1px;
  position: absolute;
  top: 50%;
}

.title_bg_grey i:before {
  right: 95%;
}

.title_bg_grey i:after {
  left: 95%;
}

.image_center {
  display: block;
  text-align: center;
  margin-bottom: 15px;
}

.authority_list {
  text-align: center;
  padding: 50px 0 100px 0;
}

.authority_list li:first-child {
  display: table;
  margin: 20px auto 20px auto;
}

.authority_list li {
  position: relative;
  width: 30.3%;
  display: inline-block;
  margin: 20px 12px;
}

.authority_list > li:nth-of-type(2) > a,
.authority_list > li:nth-of-type(4) > a {
  cursor: default;
}

.authority_list li:nth-of-type(5),
.authority_list li:nth-of-type(6) {
  width: 47%;
}

.authority_list li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 66px;
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  font-weight: 400;
  font-size: var(--font-sm);
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  transition: var(--transition);
}

.authority_list li ul {
  display: none;
  width: 100%;
  position: absolute;
  top: 100%;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  padding: 0;
}

.authority_list li:hover ul {
  display: block;
}

.authority_list li ul li {
  display: block;
  width: 100%;
  margin: 0 !important;
}

.authority_list li ul li a {
  background-color: #5a96e6;
  border-color: #5a96e6;
}

.authority_list li a:hover {
  background-color: transparent;
  color: var(--color-primary);
}

.authority_list li ul li a:hover {
  background-color: #fff;
}

.grecaptcha-badge {
  display: none !important;
}
