@media (max-width: 1450px) {
  :root {
    --navbar-height: 100px;
  }
}

@media (max-width: $breakpoint-4xl) {
  :root {
    /* authorities sizing */
    --categories-width: 280px;
    --authorities-width: 320px;
  }
}

@media (max-width: $breakpoint-lg) {
  .authority_list {
    padding: 0 !important;
  }

  .authority_list li,
  .authority_list li:nth-of-type(5),
  .authority_list li:nth-of-type(6) {
    display: block;
    width: 100%;
    margin: 20px 0;
  }
}

@media (max-width: $breakpoint-lg) {
  :root {
    --navbar-height: 110px;
  }
}

@media (prefers-reduced-motion) {
  *,
  *::before,
  *::after {
    animation-duration: 0s !important;
    transition: none !important;
    scroll-behavior: auto !important;
  }
}
